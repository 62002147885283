import { ReactElement } from 'react'
import { Marker } from 'react-map-gl/maplibre'

import { getMarkerIcon, getMarkerOffset } from './helpers'
import { KNMapLibreMarkerProps } from './types'

const KNMapLibreMarker = ({
  marker,
  onMarkerClick,
}: KNMapLibreMarkerProps): ReactElement => (
  <Marker
    latitude={marker.latitude}
    longitude={marker.longitude}
    subpixelPositioning
    rotation={marker.heading ?? 0}
    rotationAlignment={marker.heading ? 'map' : 'auto'}
    offset={getMarkerOffset(marker)}
    onClick={(event) => onMarkerClick?.(marker)}
    style={{
      ...(!onMarkerClick && {
        pointerEvents: 'none',
      })
    }}
  >
    {getMarkerIcon(marker)}
  </Marker>
)

export default KNMapLibreMarker
