import { useState, useEffect, useCallback, useMemo, useRef, ReactElement } from 'react'
import i18n from 'i18n'
import { throttle } from 'lodash'

// Map imports
import {
  Map,
  MapRef,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  MapLayerMouseEvent,
  ViewStateChangeEvent,
} from 'react-map-gl/maplibre'
import {
  GeoJSONSource,
  MapGeoJSONFeature,
} from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { circle } from '@turf/circle'
import { point, lineString, featureCollection } from '@turf/helpers'
import { nearestPoint } from '@turf/nearest-point'
import { bbox } from '@turf/bbox'
import { Point, Feature, FeatureCollection, GeoJsonProperties } from 'geojson'

// KN imports
import { relativeDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNMapLibreMarker from './KNMapLibreMarker'
import { KNMapLibreProps, MapMarker } from './types'
import {
  getMarkerTypeColor,
  getHeadingDate,
} from './helpers'
import {
  getMetadataLayer,
  getRoutesLayer,
  clustersLayer,
  clustersLabelLayer,
  geofencesLayer,
  geofencesOutlineLayer,
} from './layers'
import KNMapLibreTooltip from './KNMapLibreTooltip'
import { getEstimatedSpeedLabel, getEstimatedSpeedColor } from 'screens/TripDetails/TripDetails.helpers'

const getHeadingTooltip = (properties: GeoJsonProperties): ReactElement | null => {
  if (!properties) {
    return null
  }
  const estimatedSpeedLabel = getEstimatedSpeedLabel(properties.estimatedSpeed)
  return (
    <>
      <KNTypography variant="p3">{relativeDate(properties.lastTimestamp || properties.timestamp)}</KNTypography>
      <KNTypography component="p" variant="p5">{getHeadingDate(properties.timestamp, properties.lastTimestamp)}</KNTypography>
      {(properties.estimatedSpeed ?? 0) > 0 && (
        <KNTypography component="p" variant="p5" color="primary.light">{i18n.t(`screens.cs.trip_details.map.${estimatedSpeedLabel}`)}</KNTypography>
      )}
    </>
  )
}

const KNMapLibre = <T extends object>({
  markers,
  geoPoints,
  groupedGeoPoints,
  withHeading = false,
  onMarkerClick,
  center,
  zoom = 10,
}: KNMapLibreProps): ReactElement | null => {
  const mapRef = useRef<MapRef>(null)
  const [markersMetadata, setMarkersMetadata] = useState<FeatureCollection>()
  const [routesMetadata, setRoutesMetadata] = useState<FeatureCollection>()
  const [routes, setRoutes] = useState<FeatureCollection>()
  const [geofences, setGeofences] = useState<FeatureCollection>()
  const [visibleMarkers, setVisibleMarkers] = useState<MapMarker[]>([])
  const [headingMarker, setHeadingMarker] = useState<MapMarker>()
  const [tooltipMarkers, setTooltipMarkers] = useState<MapMarker[]>([])

  useEffect(() => {
    const markersMetadataCollection = featureCollection(
      markers.map((marker) => point(
        [
          marker.longitude,
          marker.latitude,
        ], {
          class: 'marker',
          id: marker.id,
          type: marker.type,
        } as GeoJsonProperties
      ))
    )
    setMarkersMetadata(markersMetadataCollection)

    const routesMetadataCollection = featureCollection(
      geoPoints?.map((geoPoint) => point(
        [
          geoPoint.longitude,
          geoPoint.latitude,
        ],
        {
          class: 'route',
          ...geoPoint,
        } as GeoJsonProperties
      )) ?? []
    )
    setRoutesMetadata(routesMetadataCollection)

    const routesFeatureCollection = featureCollection(
      groupedGeoPoints?.filter((group) => group.geoPoints.length > 1).map((group) =>
        lineString(
          group.geoPoints.map((geoPoint) => [ geoPoint.longitude, geoPoint.latitude ]),
          {
            speed: group.label,
            color: getEstimatedSpeedColor(group.label),
          }
        )
      ) ?? []
    )
    setRoutes(routesFeatureCollection)

    // show geofences only for unclustered markers
    const geofencesFeatureCollection = featureCollection(
      visibleMarkers.filter((marker) => marker.geofence !== undefined).map((marker) => circle(
        [
          marker.longitude,
          marker.latitude,
        ],
        marker.geofence! / 1000,
        {
          steps: 64,
          properties: {
            id: marker.id,
            class: 'geofence',
            color: marker.color ?? getMarkerTypeColor(marker.type),
          },
        }
      ))
    )
    setGeofences(geofencesFeatureCollection)
  }, [markers, visibleMarkers])

  const handleLoad = useCallback(() => {
    if (!markersMetadata || !mapRef.current) {
      return
    }

    const boundingBox = bbox(markersMetadata)
    mapRef.current?.fitBounds(
      [ [ boundingBox[0], boundingBox[1] ], [ boundingBox[2], boundingBox[3] ] ],
      {
        maxZoom: 16,
        padding: 64,
      }
    )
  }, [mapRef, markersMetadata])

  const handleMouseMove = useCallback(async (event: MapLayerMouseEvent) => {
    if (!markersMetadata || !mapRef.current) {
      return
    }

    // get all features inside a bbox around the mouse pointer
    const THRESHOLD = 16
    const features: MapGeoJSONFeature[] = event.target.queryRenderedFeatures(
      [
        [event.point.x - THRESHOLD, event.point.y - THRESHOLD],
        [event.point.x + THRESHOLD, event.point.y + THRESHOLD]
      ],
      {
        layers: ['markers_metadata', 'routes_metadata'],
      }
    )

    // get clustered markers
    const clusterFeatures = features.filter((feature) => feature.properties.cluster)
    const clusteredMarkerIds = await clusterFeatures.reduce(async (idsPromise, cluster) => {
      const ids = await idsPromise
      const source = mapRef.current!.getSource(cluster.source) as GeoJSONSource
      const clusteredMarkers = await source.getClusterLeaves(cluster.properties.cluster_id, cluster.properties.point_count, 0)
      return ids.concat(clusteredMarkers.map((feature) => feature.properties?.id))
    }, Promise.resolve([] as string[]))
    const clusteredMarkers = markers.filter((marker) => clusteredMarkerIds.includes(marker.id))

    // get unclustered markers
    const markerFeatures = features.filter((feature) => feature.properties.class === 'marker')
    const unclusteredMarkerIds = markerFeatures.map((feature) => feature.properties?.id)
    const unclusteredMarkers = markers.filter((marker) => unclusteredMarkerIds.includes(marker.id))

    // prepare markers list for tooltip
    const tooltipMarkers = [
      ...unclusteredMarkers,
      ...clusteredMarkers,
    ]

    // add heading marker if near a route
    if (withHeading) {
      const routeFeatures = features.filter((feature) => feature.properties.class === 'route')
      if (routeFeatures.length > 0) {
        const nearest = nearestPoint([event.lngLat.lng, event.lngLat.lat], featureCollection(routeFeatures as Feature<Point, GeoJsonProperties>[]))
        const headingMarker = {
          id: 'heading',
          latitude: nearest.geometry.coordinates[1],
          longitude: nearest.geometry.coordinates[0],
          type: 'HEADING',
          color: getEstimatedSpeedColor(getEstimatedSpeedLabel(nearest.properties.estimatedSpeed)),
          heading: nearest.properties.heading,
          tooltip: getHeadingTooltip(nearest.properties) ?? undefined,
        }
        tooltipMarkers.unshift(headingMarker)
        setHeadingMarker(headingMarker)
      } else {
        setHeadingMarker(undefined)
      }
    }

    setTooltipMarkers(tooltipMarkers)
  }, [mapRef, markers, markersMetadata, routesMetadata])
  const handleMouseMoveThrottled = useMemo(
    () => throttle(handleMouseMove, 66, { leading: true, trailing: false }),
    [handleMouseMove, mapRef, markers, markersMetadata, routesMetadata]
  )

  const handleVisibleMarkers = useCallback((event: ViewStateChangeEvent) => {
    if (!markersMetadata || !mapRef.current) {
      return
    }

    const features: MapGeoJSONFeature[] = event.target.queryRenderedFeatures(
      {
        layers: ['markers_metadata'],
      }
    )

    const visibleMarkerFeatures = features.filter((feature) => feature.properties.class === 'marker')
    const visibleMarkerIds = visibleMarkerFeatures.map((feature) => feature.properties?.id)
    setVisibleMarkers(markers.filter((marker) => visibleMarkerIds.includes(marker.id)))
  }, [mapRef, markers, markersMetadata])
  const handleVisibleMarkersThrottled = useMemo(
    () => throttle(handleVisibleMarkers, 66, { leading: true, trailing: false }),
    [handleVisibleMarkers, mapRef, markers, markersMetadata]
  )

  return (
    <Map
      ref={mapRef}
      mapStyle={`${process.env.PUBLIC_URL}/map/style.json`}
      onLoad={handleLoad}
      onMouseMove={handleMouseMoveThrottled}
      onZoom={handleVisibleMarkersThrottled}
      onZoomEnd={handleVisibleMarkersThrottled}
      onDrag={handleVisibleMarkersThrottled}
      onDragEnd={handleVisibleMarkersThrottled}
      dragRotate={false}
      minZoom={0}
      initialViewState={{
        zoom: 4,
      }}
    >
      <FullscreenControl position="top-left" />
      <NavigationControl position="top-left" />
      <ScaleControl />

      {markersMetadata && (
        <Source id="markers_metadata" type="geojson" data={markersMetadata} cluster clusterMaxZoom={14} clusterRadius={24}>
          <Layer {...getMetadataLayer('markers_metadata')} />
          <Layer {...clustersLayer} />
          <Layer {...clustersLabelLayer} />
        </Source>
      )}

      {routesMetadata && (
        <Source id="routes_metadata" type="geojson" data={routesMetadata}>
          <Layer {...getMetadataLayer('routes_metadata')} />
        </Source>
      )}

      {geofences && (
        <Source id="geofences" type="geojson" data={geofences}>
          <Layer {...geofencesOutlineLayer} />
          <Layer {...geofencesLayer} />
        </Source>
      )}

      {routes && (
        <Source id="routes" type="geojson" data={routes}>
          <Layer beforeId="label_other" {...getRoutesLayer('vehicle_fast')} />
          <Layer beforeId="label_other" {...getRoutesLayer('vehicle_medium')} />
          <Layer beforeId="label_other" {...getRoutesLayer('vehicle_slow')} />
          <Layer beforeId="label_other" {...getRoutesLayer('interruption')} />
        </Source>
      )}

      {headingMarker && (
        <KNMapLibreMarker key={headingMarker.id} marker={headingMarker} />
      )}

      {visibleMarkers.map((marker) => (
        <KNMapLibreMarker key={marker.id} marker={marker} onMarkerClick={onMarkerClick} />
      ))}

      {tooltipMarkers.length > 0 && (
        <KNMapLibreTooltip markers={tooltipMarkers} />
      )}
    </Map>
  )
}

export default KNMapLibre
