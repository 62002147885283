import {
  CircleLayerSpecification,
  LineLayerSpecification,
  FillLayerSpecification,
  SymbolLayerSpecification,
} from 'react-map-gl/maplibre'

import theme from 'assets/theme'
import { getMarkerTypeColor } from './helpers'

export const getMetadataLayer = (id: string): Omit<CircleLayerSpecification, 'source'> => {
  return {
    id,
    type: 'circle',
    paint: {
      'circle-opacity': 0,
    },
  }
}

export const getRoutesLayer = (speed: string): Omit<LineLayerSpecification, 'source'> => {
  return {
    id: `routes_${speed}`,
    type: 'line',
    filter: ['==', 'speed', speed],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': [
        'interpolate',
        ['exponential', 1.2],
        ['zoom'],
        2,
        2,
        6,
        4,
        10,
        6,
      ],
      ...(speed === 'interruption' && {
        'line-dasharray': [0.5, 2],
      }),
    }
  }
}

export const clustersLayer: Omit<CircleLayerSpecification, 'source'> = {
  id: 'clusters',
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-radius': [
        'step',
        ['get', 'point_count'],
        8,
        2,
        16,
        4,
        24,
    ],
    'circle-color': theme.palette.primary.main,
    'circle-opacity': 0.8,
  },
}

export const clustersLabelLayer: Omit<SymbolLayerSpecification, 'source'> = {
  id: 'clusters_labels',
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Noto Sans Bold'],
    'text-size': 12,
  },
  paint: {
    'text-color': "#fff",
  },
}

export const geofencesLayer: Omit<FillLayerSpecification, 'source'> = {
  id: 'geofences',
  type: 'fill',
  paint: {
    'fill-color': [
      'coalesce',
      ['get', 'color'],
      getMarkerTypeColor(),
    ],
    'fill-opacity': 0.1,
  }
}

export const geofencesOutlineLayer: Omit<LineLayerSpecification, 'source'> = {
  id: 'geofences_outline',
  type: 'line',
  paint: {
    'line-color': [
      'coalesce',
      ['get', 'color'],
      getMarkerTypeColor(),
    ],
    'line-opacity': 0.5,
    'line-width': [
      'interpolate',
      ['exponential', 1.2],
      ['zoom'],
      0,
      1,
      16,
      3,
    ],
  },
}
